/* eslint-disable */
import * as types from './graphql';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
  '\n  fragment HeroBannerFragment on VildarPageComponentsBanner {\n    __typename\n    banner {\n      cta {\n        external_link {\n          href\n          title\n        }\n        internal_linkConnection {\n          edges {\n            node {\n              ... on VildarPage {\n                navigation_title\n                url\n              }\n            }\n          }\n        }\n        label\n      }\n      headline\n      image {\n        alt_text\n        crop_resize\n        gravity\n        imgConnection {\n          ...SysAssetConnectionFragment\n        }\n      }\n      layout\n      paragraph\n      rounded\n      styled\n      mobile_layout\n      object_fit\n      expand_image\n      main_banner\n    }\n  }\n':
    types.HeroBannerFragmentFragmentDoc,
  '\n  fragment VildarCardSectionFragment on VildarPageComponentsCards {\n    __typename\n    cards {\n      card_sectionConnection {\n        edges {\n          __typename\n          node {\n            ...VildarCardSectionCardsFragment\n          }\n        }\n      }\n    }\n  }\n':
    types.VildarCardSectionFragmentFragmentDoc,
  '\n  fragment VildarCardSectionCardsFragment on VildarCardSection {\n    __typename\n    title\n    display_title\n\n    ...CardSectionSimpleBoxesFragment\n    sidebar\n    post_limit\n    ...CardSectionPersonsFragment\n    paragraph\n    pagination\n    link {\n      external_url {\n        href\n        title\n      }\n      internal_urlConnection {\n        edges {\n          node {\n            ... on VildarPage {\n              navigation_title\n              url\n            }\n          }\n        }\n      }\n      label\n    }\n    type\n    card_template\n  }\n':
    types.VildarCardSectionCardsFragmentFragmentDoc,
  '\n  fragment CardSectionSimpleBoxesFragment on VildarCardSection {\n    simple_boxes {\n      ... on VildarCardSectionSimpleBoxesBox {\n        __typename\n        box {\n          background_color\n          headline\n          text\n        }\n      }\n    }\n  }\n':
    types.CardSectionSimpleBoxesFragmentFragmentDoc,
  '\n  fragment CardSectionPersonsFragment on VildarCardSection {\n    persons {\n      ... on VildarCardSectionPersonsPerson {\n        __typename\n        person {\n          description\n          name\n          image {\n            alt_text\n            crop_resize\n            gravity\n            assetConnection {\n              edges {\n                node {\n                  dimension {\n                    height\n                    width\n                  }\n                  system {\n                    uid\n                  }\n                  content_type\n                }\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n':
    types.CardSectionPersonsFragmentFragmentDoc,
  '\n  fragment ContentSectionFragment on VildarPageComponentsContentSection {\n    ... on VildarPageComponentsContentSection {\n      __typename\n      content_section {\n        cta {\n          label\n          external_link {\n            href\n            title\n          }\n          internal_linkConnection {\n            edges {\n              node {\n                ... on VildarPage {\n                  title\n                  url\n                }\n                ... on VildarContentPosts {\n                  title\n                  url\n                }\n              }\n            }\n            totalCount\n          }\n        }\n        headline\n        layout\n        paragraph\n        variant\n        image {\n          alt_text\n          crop_resize\n          gravity\n          imgConnection {\n            ...SysAssetConnectionFragment\n          }\n        }\n        video {\n          url\n          autoplay\n          controls\n        }\n        content_postConnection {\n          edges {\n            node {\n              ... on VildarContentPosts {\n                date\n                show_date\n                display_title\n                short_description\n                url\n                type\n                image {\n                  crop_resize\n                  gravity\n                  alt_text\n                  assetConnection {\n                    ...SysAssetConnectionFragment\n                  }\n                }\n              }\n            }\n          }\n          totalCount\n        }\n      }\n    }\n  }\n':
    types.ContentSectionFragmentFragmentDoc,
  '\n  fragment DocumentsSectionFragment on VildarPageComponentsDocuments {\n    __typename\n    documents {\n      headline\n      paragraph\n      files {\n        title\n        file_referenceConnection {\n          edges {\n            node {\n              url\n            }\n          }\n        }\n      }\n    }\n  }\n':
    types.DocumentsSectionFragmentFragmentDoc,
  '\n  fragment VildarIframeSectionFragment on VildarPageComponentsForms {\n    __typename\n    forms {\n      iframe_type\n      iframe_url {\n        href\n        title\n      }\n    }\n  }\n':
    types.VildarIframeSectionFragmentFragmentDoc,
  '\n  query NavigationAndFooter($navId: String!, $footerId: String!, $locale: String!) {\n    navigation: vildar_navigation(uid: $navId, locale: $locale, fallback_locale: true) {\n      ...NavigationMenuFragment\n    }\n    footer: vildar_navigation(uid: $footerId, locale: $locale, fallback_locale: true) {\n      ...NavigationAndFooterFragment\n    }\n  }\n':
    types.NavigationAndFooterDocument,
  '\n  query NavigationMenu($uid: String!, $locale: String!, $footer: Boolean!) {\n    vildar_navigation(uid: $uid, locale: $locale, fallback_locale: true) {\n      ...NavigationMenuFragment\n      footer_specific @include(if: $footer) {\n        ...FooterSpecificFragment\n      }\n    }\n  }\n':
    types.NavigationMenuDocument,
  '\n  fragment NavigationAndFooterFragment on VildarNavigation {\n    ...NavigationMenuFragment\n    footer_specific {\n      ...FooterSpecificFragment\n    }\n  }\n':
    types.NavigationAndFooterFragmentFragmentDoc,
  '\n  fragment NavigationMenuFragment on VildarNavigation {\n    buttons {\n      ... on VildarNavigationButtonsLanguagePicker {\n        __typename\n        language_picker {\n          languages\n        }\n      }\n      ... on VildarNavigationButtonsCta {\n        __typename\n        cta {\n          button_style\n          external_link {\n            href\n            title\n          }\n          internal_linkConnection {\n            edges {\n              node {\n                ... on VildarPage {\n                  navigation_title\n                  url\n                }\n              }\n            }\n          }\n          label\n        }\n      }\n    }\n\n    links {\n      ... on VildarNavigationLinksCta {\n        __typename\n        cta {\n          external_link {\n            href\n            title\n          }\n          label\n          internal_linkConnection {\n            edges {\n              node {\n                ... on VildarPage {\n                  navigation_title\n                  url\n                }\n              }\n            }\n          }\n        }\n      }\n    }\n    logo {\n      alt_text\n      assetConnection {\n        ...SysAssetConnectionFragment\n      }\n      crop_resize\n      gravity\n    }\n    pagesConnection {\n      edges {\n        node {\n          ... on VildarPage {\n            navigation_title\n            url\n          }\n        }\n      }\n    }\n    title\n  }\n':
    types.NavigationMenuFragmentFragmentDoc,
  '\n  fragment LanguagePickerFragment on VildarNavigationButtonsLanguagePicker {\n    __typename\n    language_picker {\n      languages\n    }\n  }\n':
    types.LanguagePickerFragmentFragmentDoc,
  '\n  fragment FooterSpecificFragment on VildarNavigationFooterSpecific {\n    headline\n    email\n    icelandair_logo {\n      assetConnection {\n        edges {\n          node {\n            system {\n              uid\n            }\n            content_type\n            dimension {\n              height\n              width\n            }\n          }\n        }\n      }\n      crop_resize\n      gravity\n    }\n    impressum\n    phone\n  }\n':
    types.FooterSpecificFragmentFragmentDoc,
  '\n  query getPageByUrl($url: String!, $locale: String!) {\n    all_vildar_page(fallback_locale: true, locale: $locale, where: { url: $url }) {\n      __typename\n      items {\n        ...PageFragment\n        ...ComponentsFragment\n      }\n    }\n  }\n':
    types.GetPageByUrlDocument,
  '\n  fragment VildarPageQueryFragment on VildarPage {\n    ...PageFragment\n    ...ComponentsFragment\n  }\n':
    types.VildarPageQueryFragmentFragmentDoc,
  '\n  fragment ComponentsFragment on VildarPage {\n    components {\n      ...HeroBannerFragment\n      ...ContentSectionFragment\n      ...VildarCardSectionFragment\n      ...VildarIframeSectionFragment\n      ...VildarTabSectionFragment\n      ...DocumentsSectionFragment\n    }\n  }\n':
    types.ComponentsFragmentFragmentDoc,
  '\n  fragment PageFragment on VildarPage {\n    __typename\n    title\n    url\n    hidden\n    seo {\n      description\n      title\n      imageConnection {\n        __typename\n        edges {\n          node {\n            __typename\n            dimension {\n              height\n              width\n            }\n            system {\n              uid\n            }\n            content_type\n          }\n        }\n      }\n    }\n    translation_strings {\n      groups\n    }\n    system {\n      uid\n      content_type_uid\n      updated_at\n    }\n  }\n':
    types.PageFragmentFragmentDoc,
  '\n  fragment TabSectionCardSectionFragment on VildarPageComponentsTabsSectionBlockTabCardSectionNode {\n    ... on VildarCardSection {\n      title\n      display_title\n      link {\n        external_url {\n          href\n          title\n        }\n        internal_urlConnection {\n          edges {\n            node {\n              ... on VildarPage {\n                title\n                navigation_title\n                url\n              }\n            }\n          }\n          totalCount\n        }\n        label\n      }\n      card_template\n      pagination\n      paragraph\n      post_limit\n      sidebar\n      type\n      simple_boxes {\n        ... on VildarCardSectionSimpleBoxesBox {\n          __typename\n          box {\n            background_color\n            headline\n            text\n          }\n        }\n      }\n      persons {\n        ... on VildarCardSectionPersonsPerson {\n          __typename\n          person {\n            description\n            name\n          }\n        }\n      }\n    }\n  }\n':
    types.TabSectionCardSectionFragmentFragmentDoc,
  '\n  fragment VildarTabSectionFragment on VildarPageComponentsTabsSection {\n    ... on VildarPageComponentsTabsSection {\n      __typename\n      tabs_section {\n        tab {\n          title\n          card_sectionConnection {\n            edges {\n              node {\n                ...TabSectionCardSectionFragment\n              }\n            }\n            totalCount\n          }\n        }\n      }\n    }\n  }\n':
    types.VildarTabSectionFragmentFragmentDoc,
  '\n  query getAllTranslationGroups {\n    all_vildar_translation_group {\n      items {\n        code_reference\n        system {\n          uid\n        }\n      }\n    }\n  }\n':
    types.GetAllTranslationGroupsDocument,
  '\n  query getContentPosts($type: String!, $limit: Int!) {\n    all_vildar_content_posts(where: { type: $type }, limit: $limit, order_by: updated_at_DESC) {\n      ...ContentPostsListFragment\n    }\n  }\n':
    types.GetContentPostsDocument,
  '\n  query getContentPostByLocale($type: String!, $limit: Int!, $locale: String!, $skip: Int!) {\n    all_vildar_content_posts(\n      where: { type: $type }\n      locale: $locale\n      limit: $limit\n      skip: $skip\n      order_by: updated_at_DESC\n    ) {\n      ...ContentPostsListFragment\n    }\n  }\n':
    types.GetContentPostByLocaleDocument,
  '\n  query getContentPostsByUrlAndType($type: String!, $limit: Int!, $url: String!, $locale: String!) {\n    all_vildar_content_posts(\n      where: { type: $type, url: $url }\n      locale: $locale\n      limit: $limit\n      order_by: updated_at_DESC\n    ) {\n      ...ContentPostsListFragment\n    }\n  }\n':
    types.GetContentPostsByUrlAndTypeDocument,
  '\n  query getContentPostsByUrl($limit: Int!, $url: String!, $locale: String!) {\n    all_vildar_content_posts(where: { url: $url }, locale: $locale, limit: $limit, order_by: updated_at_DESC) {\n      ...ContentPostsListFragment\n    }\n  }\n':
    types.GetContentPostsByUrlDocument,
  '\n  fragment ContentPostsListFragment on AllVildarContentPosts {\n    total\n    items {\n      ...ContentPostFragment\n    }\n  }\n':
    types.ContentPostsListFragmentFragmentDoc,
  '\n  fragment ImageFragment on VildarContentPosts {\n    image {\n      alt_text\n      crop_resize\n      gravity\n      assetConnection {\n        ...SysAssetConnectionFragment\n      }\n    }\n  }\n':
    types.ImageFragmentFragmentDoc,
  '\n  fragment ContentPostFragment on VildarContentPosts {\n    __typename\n    text\n    title\n    type\n    url\n    short_description\n    display_title\n    date\n    show_date\n    link_label\n    next_post\n    ...ImageFragment\n    system {\n      updated_at\n    }\n  }\n':
    types.ContentPostFragmentFragmentDoc,
  '\n  query getAllContentPosts {\n    is: all_vildar_content_posts(locale: "is-is", where: {locale: "is-is"}) {\n      items {\n        url\n        system {\n          uid\n          locale\n          updated_at\n        }\n        type\n        title\n      }\n    }\n    en: all_vildar_content_posts(locale: "en-us", where: {locale: "en-us"}) {\n      items {\n        url\n        system {\n          uid\n          locale\n          updated_at\n        }\n        type\n        title\n      }\n    }\n  }\n':
    types.GetAllContentPostsDocument,
  '\n  query GetAllPagesBy {\n    is: all_vildar_page(fallback_locale: true, locale: "is-is") {\n      items {\n        url\n        navigation_title\n        system {\n          uid\n          updated_at\n        }\n      }\n    }\n    en: all_vildar_page(fallback_locale: true, locale: "en-us") {\n      items {\n        url\n        navigation_title\n        system {\n          uid\n          updated_at\n        }\n      }\n    }\n  }\n':
    types.GetAllPagesByDocument,
  '\n  query getSingleTranslationGroup($locale: String!, $uid: String!) {\n    vildar_translation_group(uid: $uid, locale: $locale, fallback_locale: true) {\n      code_reference\n      strings {\n        ... on VildarTranslationGroupStringsTranslation {\n          __typename\n          translation {\n            key\n            value\n          }\n        }\n      }\n    }\n  }\n':
    types.GetSingleTranslationGroupDocument,
  '\n  fragment SysAssetConnectionFragment on SysAssetConnection {\n    __typename\n    edges {\n      node {\n        content_type\n        description\n        dimension {\n          height\n          width\n        }\n        system {\n          uid\n        }\n      }\n    }\n    totalCount\n  }\n':
    types.SysAssetConnectionFragmentFragmentDoc,
};

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment HeroBannerFragment on VildarPageComponentsBanner {\n    __typename\n    banner {\n      cta {\n        external_link {\n          href\n          title\n        }\n        internal_linkConnection {\n          edges {\n            node {\n              ... on VildarPage {\n                navigation_title\n                url\n              }\n            }\n          }\n        }\n        label\n      }\n      headline\n      image {\n        alt_text\n        crop_resize\n        gravity\n        imgConnection {\n          ...SysAssetConnectionFragment\n        }\n      }\n      layout\n      paragraph\n      rounded\n      styled\n      mobile_layout\n      object_fit\n      expand_image\n      main_banner\n    }\n  }\n'
): typeof import('./graphql').HeroBannerFragmentFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment VildarCardSectionFragment on VildarPageComponentsCards {\n    __typename\n    cards {\n      card_sectionConnection {\n        edges {\n          __typename\n          node {\n            ...VildarCardSectionCardsFragment\n          }\n        }\n      }\n    }\n  }\n'
): typeof import('./graphql').VildarCardSectionFragmentFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment VildarCardSectionCardsFragment on VildarCardSection {\n    __typename\n    title\n    display_title\n\n    ...CardSectionSimpleBoxesFragment\n    sidebar\n    post_limit\n    ...CardSectionPersonsFragment\n    paragraph\n    pagination\n    link {\n      external_url {\n        href\n        title\n      }\n      internal_urlConnection {\n        edges {\n          node {\n            ... on VildarPage {\n              navigation_title\n              url\n            }\n          }\n        }\n      }\n      label\n    }\n    type\n    card_template\n  }\n'
): typeof import('./graphql').VildarCardSectionCardsFragmentFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment CardSectionSimpleBoxesFragment on VildarCardSection {\n    simple_boxes {\n      ... on VildarCardSectionSimpleBoxesBox {\n        __typename\n        box {\n          background_color\n          headline\n          text\n        }\n      }\n    }\n  }\n'
): typeof import('./graphql').CardSectionSimpleBoxesFragmentFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment CardSectionPersonsFragment on VildarCardSection {\n    persons {\n      ... on VildarCardSectionPersonsPerson {\n        __typename\n        person {\n          description\n          name\n          image {\n            alt_text\n            crop_resize\n            gravity\n            assetConnection {\n              edges {\n                node {\n                  dimension {\n                    height\n                    width\n                  }\n                  system {\n                    uid\n                  }\n                  content_type\n                }\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n'
): typeof import('./graphql').CardSectionPersonsFragmentFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment ContentSectionFragment on VildarPageComponentsContentSection {\n    ... on VildarPageComponentsContentSection {\n      __typename\n      content_section {\n        cta {\n          label\n          external_link {\n            href\n            title\n          }\n          internal_linkConnection {\n            edges {\n              node {\n                ... on VildarPage {\n                  title\n                  url\n                }\n                ... on VildarContentPosts {\n                  title\n                  url\n                }\n              }\n            }\n            totalCount\n          }\n        }\n        headline\n        layout\n        paragraph\n        variant\n        image {\n          alt_text\n          crop_resize\n          gravity\n          imgConnection {\n            ...SysAssetConnectionFragment\n          }\n        }\n        video {\n          url\n          autoplay\n          controls\n        }\n        content_postConnection {\n          edges {\n            node {\n              ... on VildarContentPosts {\n                date\n                show_date\n                display_title\n                short_description\n                url\n                type\n                image {\n                  crop_resize\n                  gravity\n                  alt_text\n                  assetConnection {\n                    ...SysAssetConnectionFragment\n                  }\n                }\n              }\n            }\n          }\n          totalCount\n        }\n      }\n    }\n  }\n'
): typeof import('./graphql').ContentSectionFragmentFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment DocumentsSectionFragment on VildarPageComponentsDocuments {\n    __typename\n    documents {\n      headline\n      paragraph\n      files {\n        title\n        file_referenceConnection {\n          edges {\n            node {\n              url\n            }\n          }\n        }\n      }\n    }\n  }\n'
): typeof import('./graphql').DocumentsSectionFragmentFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment VildarIframeSectionFragment on VildarPageComponentsForms {\n    __typename\n    forms {\n      iframe_type\n      iframe_url {\n        href\n        title\n      }\n    }\n  }\n'
): typeof import('./graphql').VildarIframeSectionFragmentFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query NavigationAndFooter($navId: String!, $footerId: String!, $locale: String!) {\n    navigation: vildar_navigation(uid: $navId, locale: $locale, fallback_locale: true) {\n      ...NavigationMenuFragment\n    }\n    footer: vildar_navigation(uid: $footerId, locale: $locale, fallback_locale: true) {\n      ...NavigationAndFooterFragment\n    }\n  }\n'
): typeof import('./graphql').NavigationAndFooterDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query NavigationMenu($uid: String!, $locale: String!, $footer: Boolean!) {\n    vildar_navigation(uid: $uid, locale: $locale, fallback_locale: true) {\n      ...NavigationMenuFragment\n      footer_specific @include(if: $footer) {\n        ...FooterSpecificFragment\n      }\n    }\n  }\n'
): typeof import('./graphql').NavigationMenuDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment NavigationAndFooterFragment on VildarNavigation {\n    ...NavigationMenuFragment\n    footer_specific {\n      ...FooterSpecificFragment\n    }\n  }\n'
): typeof import('./graphql').NavigationAndFooterFragmentFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment NavigationMenuFragment on VildarNavigation {\n    buttons {\n      ... on VildarNavigationButtonsLanguagePicker {\n        __typename\n        language_picker {\n          languages\n        }\n      }\n      ... on VildarNavigationButtonsCta {\n        __typename\n        cta {\n          button_style\n          external_link {\n            href\n            title\n          }\n          internal_linkConnection {\n            edges {\n              node {\n                ... on VildarPage {\n                  navigation_title\n                  url\n                }\n              }\n            }\n          }\n          label\n        }\n      }\n    }\n\n    links {\n      ... on VildarNavigationLinksCta {\n        __typename\n        cta {\n          external_link {\n            href\n            title\n          }\n          label\n          internal_linkConnection {\n            edges {\n              node {\n                ... on VildarPage {\n                  navigation_title\n                  url\n                }\n              }\n            }\n          }\n        }\n      }\n    }\n    logo {\n      alt_text\n      assetConnection {\n        ...SysAssetConnectionFragment\n      }\n      crop_resize\n      gravity\n    }\n    pagesConnection {\n      edges {\n        node {\n          ... on VildarPage {\n            navigation_title\n            url\n          }\n        }\n      }\n    }\n    title\n  }\n'
): typeof import('./graphql').NavigationMenuFragmentFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment LanguagePickerFragment on VildarNavigationButtonsLanguagePicker {\n    __typename\n    language_picker {\n      languages\n    }\n  }\n'
): typeof import('./graphql').LanguagePickerFragmentFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment FooterSpecificFragment on VildarNavigationFooterSpecific {\n    headline\n    email\n    icelandair_logo {\n      assetConnection {\n        edges {\n          node {\n            system {\n              uid\n            }\n            content_type\n            dimension {\n              height\n              width\n            }\n          }\n        }\n      }\n      crop_resize\n      gravity\n    }\n    impressum\n    phone\n  }\n'
): typeof import('./graphql').FooterSpecificFragmentFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getPageByUrl($url: String!, $locale: String!) {\n    all_vildar_page(fallback_locale: true, locale: $locale, where: { url: $url }) {\n      __typename\n      items {\n        ...PageFragment\n        ...ComponentsFragment\n      }\n    }\n  }\n'
): typeof import('./graphql').GetPageByUrlDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment VildarPageQueryFragment on VildarPage {\n    ...PageFragment\n    ...ComponentsFragment\n  }\n'
): typeof import('./graphql').VildarPageQueryFragmentFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment ComponentsFragment on VildarPage {\n    components {\n      ...HeroBannerFragment\n      ...ContentSectionFragment\n      ...VildarCardSectionFragment\n      ...VildarIframeSectionFragment\n      ...VildarTabSectionFragment\n      ...DocumentsSectionFragment\n    }\n  }\n'
): typeof import('./graphql').ComponentsFragmentFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment PageFragment on VildarPage {\n    __typename\n    title\n    url\n    hidden\n    seo {\n      description\n      title\n      imageConnection {\n        __typename\n        edges {\n          node {\n            __typename\n            dimension {\n              height\n              width\n            }\n            system {\n              uid\n            }\n            content_type\n          }\n        }\n      }\n    }\n    translation_strings {\n      groups\n    }\n    system {\n      uid\n      content_type_uid\n      updated_at\n    }\n  }\n'
): typeof import('./graphql').PageFragmentFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment TabSectionCardSectionFragment on VildarPageComponentsTabsSectionBlockTabCardSectionNode {\n    ... on VildarCardSection {\n      title\n      display_title\n      link {\n        external_url {\n          href\n          title\n        }\n        internal_urlConnection {\n          edges {\n            node {\n              ... on VildarPage {\n                title\n                navigation_title\n                url\n              }\n            }\n          }\n          totalCount\n        }\n        label\n      }\n      card_template\n      pagination\n      paragraph\n      post_limit\n      sidebar\n      type\n      simple_boxes {\n        ... on VildarCardSectionSimpleBoxesBox {\n          __typename\n          box {\n            background_color\n            headline\n            text\n          }\n        }\n      }\n      persons {\n        ... on VildarCardSectionPersonsPerson {\n          __typename\n          person {\n            description\n            name\n          }\n        }\n      }\n    }\n  }\n'
): typeof import('./graphql').TabSectionCardSectionFragmentFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment VildarTabSectionFragment on VildarPageComponentsTabsSection {\n    ... on VildarPageComponentsTabsSection {\n      __typename\n      tabs_section {\n        tab {\n          title\n          card_sectionConnection {\n            edges {\n              node {\n                ...TabSectionCardSectionFragment\n              }\n            }\n            totalCount\n          }\n        }\n      }\n    }\n  }\n'
): typeof import('./graphql').VildarTabSectionFragmentFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getAllTranslationGroups {\n    all_vildar_translation_group {\n      items {\n        code_reference\n        system {\n          uid\n        }\n      }\n    }\n  }\n'
): typeof import('./graphql').GetAllTranslationGroupsDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getContentPosts($type: String!, $limit: Int!) {\n    all_vildar_content_posts(where: { type: $type }, limit: $limit, order_by: updated_at_DESC) {\n      ...ContentPostsListFragment\n    }\n  }\n'
): typeof import('./graphql').GetContentPostsDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getContentPostByLocale($type: String!, $limit: Int!, $locale: String!, $skip: Int!) {\n    all_vildar_content_posts(\n      where: { type: $type }\n      locale: $locale\n      limit: $limit\n      skip: $skip\n      order_by: updated_at_DESC\n    ) {\n      ...ContentPostsListFragment\n    }\n  }\n'
): typeof import('./graphql').GetContentPostByLocaleDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getContentPostsByUrlAndType($type: String!, $limit: Int!, $url: String!, $locale: String!) {\n    all_vildar_content_posts(\n      where: { type: $type, url: $url }\n      locale: $locale\n      limit: $limit\n      order_by: updated_at_DESC\n    ) {\n      ...ContentPostsListFragment\n    }\n  }\n'
): typeof import('./graphql').GetContentPostsByUrlAndTypeDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getContentPostsByUrl($limit: Int!, $url: String!, $locale: String!) {\n    all_vildar_content_posts(where: { url: $url }, locale: $locale, limit: $limit, order_by: updated_at_DESC) {\n      ...ContentPostsListFragment\n    }\n  }\n'
): typeof import('./graphql').GetContentPostsByUrlDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment ContentPostsListFragment on AllVildarContentPosts {\n    total\n    items {\n      ...ContentPostFragment\n    }\n  }\n'
): typeof import('./graphql').ContentPostsListFragmentFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment ImageFragment on VildarContentPosts {\n    image {\n      alt_text\n      crop_resize\n      gravity\n      assetConnection {\n        ...SysAssetConnectionFragment\n      }\n    }\n  }\n'
): typeof import('./graphql').ImageFragmentFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment ContentPostFragment on VildarContentPosts {\n    __typename\n    text\n    title\n    type\n    url\n    short_description\n    display_title\n    date\n    show_date\n    link_label\n    next_post\n    ...ImageFragment\n    system {\n      updated_at\n    }\n  }\n'
): typeof import('./graphql').ContentPostFragmentFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getAllContentPosts {\n    is: all_vildar_content_posts(locale: "is-is", where: {locale: "is-is"}) {\n      items {\n        url\n        system {\n          uid\n          locale\n          updated_at\n        }\n        type\n        title\n      }\n    }\n    en: all_vildar_content_posts(locale: "en-us", where: {locale: "en-us"}) {\n      items {\n        url\n        system {\n          uid\n          locale\n          updated_at\n        }\n        type\n        title\n      }\n    }\n  }\n'
): typeof import('./graphql').GetAllContentPostsDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query GetAllPagesBy {\n    is: all_vildar_page(fallback_locale: true, locale: "is-is") {\n      items {\n        url\n        navigation_title\n        system {\n          uid\n          updated_at\n        }\n      }\n    }\n    en: all_vildar_page(fallback_locale: true, locale: "en-us") {\n      items {\n        url\n        navigation_title\n        system {\n          uid\n          updated_at\n        }\n      }\n    }\n  }\n'
): typeof import('./graphql').GetAllPagesByDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getSingleTranslationGroup($locale: String!, $uid: String!) {\n    vildar_translation_group(uid: $uid, locale: $locale, fallback_locale: true) {\n      code_reference\n      strings {\n        ... on VildarTranslationGroupStringsTranslation {\n          __typename\n          translation {\n            key\n            value\n          }\n        }\n      }\n    }\n  }\n'
): typeof import('./graphql').GetSingleTranslationGroupDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment SysAssetConnectionFragment on SysAssetConnection {\n    __typename\n    edges {\n      node {\n        content_type\n        description\n        dimension {\n          height\n          width\n        }\n        system {\n          uid\n        }\n      }\n    }\n    totalCount\n  }\n'
): typeof import('./graphql').SysAssetConnectionFragmentFragmentDoc;

export function graphql(source: string) {
  return (documents as any)[source] ?? {};
}
